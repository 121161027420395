export const setFileNameModalContent = () => {
  const exampleModal = document.getElementById("release-modal");
  if (exampleModal) {
    exampleModal.addEventListener("show.bs.modal", (event) => {
      // Button that triggered the modal
      const button = event.relatedTarget;
      // Extract info from data-bs-* attributes
      const networkName = button.getAttribute("data-bs-network-name");
      const channel = button.getAttribute("data-bs-channel");
      const fileName = button.getAttribute("data-bs-file-name");
      const command = button.getAttribute("data-bs-command");
      const timestamp = button.getAttribute("data-bs-timestamp");

      // If necessary, you could initiate an Ajax request here
      // and then do the updating in a callback.

      // Update the modal's content.
      document.getElementById("network-name").innerText = networkName;
      document.getElementById("channel").innerText = channel;
      document.getElementById("file-name").innerText = fileName;
      document.getElementById("command").innerText = command;
      document.getElementById("timestamp").innerText = timestamp;
      document.getElementById("modal-copy-cmd").dataset.cmd = command;
    });
  }
};
