import autoComplete from "@tarekraafat/autocomplete.js";

const urls = JSON.parse(document.getElementById("urls").textContent);

export const initAutoComplete = () => {
  new autoComplete({
    selector: "#autoComplete",
    placeHolder: "Release name",
    threshold: 2,
    debounce: 300, // Milliseconds value
    searchEngine: "loose",
    data: {
      src: async (query) => {
        try {
          // Fetch Data from external Source
          const source = await fetch(`${urls.API_URL}/search?q=${query}`);
          // Data should be an array of `Objects` or `Strings`
          const data = await source.json();
          return data.results;
        } catch (error) {
          return error;
        }
      },
      // Data source 'Object' key to be searched
      keys: ["file_name"],
    },
    resultsList: {
      maxResults: 10,
      class: "list-group rounded-3",
      element: (list, data) => {
        console.log("data", data);
        if (!data.results.length) {
          // Create "No Results" message element
          const message = document.createElement("div");
          // Add class to the created element
          message.setAttribute("class", "alert alert-info mb-0");
          message.setAttribute("role", "alert");
          // Add message text content
          message.innerText = `No results for: "${data.query}"`;
          // Append message element to the results list
          list.prepend(message);
        } else {
          const message = document.createElement("a");
          message.setAttribute(
            "href",
            `${urls.SITE_URL}/search?file_name=${data.query}`,
          );
          message.setAttribute("class", "btn btn-primary");
          message.innerText = "All";
          list.append(message);
        }
      },
      noResults: true,
    },
    resultItem: {
      highlight: true,
      class: "list-group-item autocomplete-release",
    },
    events: {
      input: {
        selection: (event) => {
          const obj = event.detail.selection.value;
          window.location = `${urls.SITE_URL}/release/${obj.id}`;
        },
      },
    },
  });
};
