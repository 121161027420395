import * as bootstrap from "bootstrap";
import "../scss/main.scss";
import { setFileNameModalContent } from "./modal";
import { Theme } from "./theme";
import { initAutoComplete } from "./complete";

const ready = (callback) => {
  if (document.readyState != "loading") callback();
  else document.addEventListener("DOMContentLoaded", callback);
};

const focusInput = () => {
  const input = document.getElementById("search-input");

  if (input) {
    input.focus();
  }
};

const setupToolTips = () => {
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]',
  );
  const tooltipList = [...tooltipTriggerList].map(
    (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl),
  );
};

const showCopiedToast = () => {
  const toastLiveExample = document.getElementById("copied-to-clipboard-toast");
  const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample);
  toastBootstrap.show();
};

const writeClipboardText = (event) => {
  const btn = event.target.parentElement;
  navigator.clipboard
    .writeText(btn.dataset.cmd)
    .then(() => {
      showCopiedToast();
    })
    .catch((error) => {
      console.error("Could not copy to clipboard:", error.message);
    });
};

ready(() => {
  // Handles light / dark theme
  new Theme();

  focusInput();
  setupToolTips();
  setFileNameModalContent();

  document.querySelectorAll(".copy-cmd").forEach((clipboard) => {
    clipboard.addEventListener("click", (event) => writeClipboardText(event));
  });

  // Show autocomplete
  const autoComplete = document.getElementById("search-form");
  if (autoComplete) {
    initAutoComplete();
    autoComplete.classList.remove("invisible");
  }
});
